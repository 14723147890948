// Set up multi-submit protection on forms
document.addEventListener("DOMContentLoaded", () => {
  const forms = document.querySelectorAll("form");
  forms.forEach(function(form) {
    form.addEventListener("submit", () => {
      const button = form.querySelector("input[type=\"submit\"]");
      if (button) {
        button.disabled = true;
      }
    });
  });
});
